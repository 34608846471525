import React from "react";

const InformationComponent = () => {
  return (
    <div className="information">
      <div className="informationContainer">
        <h2>Important Information</h2>
        <p>Test</p>
        <p>Test2</p>
        
      </div>
    </div>
  );
};

export default InformationComponent;
