// StagePicker.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import size from "../img/size.svg";
import Modal from "../components/modal";
import info from "../img/info.svg";

const API_BASE_URL = "https://admin.mobile-bühne.at";

const StagePicker = ({ register, setBuehnenart, onNextDropdown, control, onSelectStage }) => {
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedScene, setSelectedScene] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Добавлено новое состояние
  const [loading, setLoading] = useState(true);
  const [selectedSize, setSelectedSize] = useState(null); // Новое состояние для хранения выбранного размера
  const [isButtonActive, setIsButtonActive] = useState(false); // Новое состояние для активации/деактивации кнопки

  const fetchStages = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/items/stages`);
      // ?fields=id,size
      return response.data;
    } catch (error) {
      console.error("Error fetching stages from Directus:", error);
    }
  };

  useEffect(() => {
    const fetchStagesAndSetState = async () => {
      try {
        const stagesData = await fetchStages();
        setStages(stagesData.data);

        if (stagesData.data.length > 0 && !selectedStage) {
          const initialStage = stagesData.data[0];
          setSelectedStage(initialStage);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchStagesAndSetState();
  }, [selectedStage]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!stages.length) {
    return <p>No stages found</p>;
  }

  const handleSizeSelection = (e, stage) => {
    setBuehnenart(e.target.value);
    setSelectedSize(e.target.value);
    setIsButtonActive(true); // Активация кнопки при выборе размера
  };

  const handleNextButtonClick = () => {
    onNextDropdown(); // Вызываем функцию для перехода к следующему dropdown
    console.log("Weiter button clicked");
  };

  const openModal = (scene) => {
    setSelectedScene(scene);
    setIsModalOpen(true);
  };

  return (
    <div className="buehnenpicker-wrapper">
      {stages.map((stage) => (
        <div key={stage.id} className="sizeContainer">
          <input
            type="radio"
            className=""
            value={stage.size}
            id={`groesse-${stage.id}`}
            {...register("buehnenart", {
              required: "Bitte einen Bühnengröße auswählen",
            })}
            onClick={(e) => handleSizeSelection(e, stage)}
            checked={selectedSize === stage.size}
          />
          <label
            id="hoverSize"
            className={`option${
              selectedSize === stage.size ? " selected" : ""
            }`}
            htmlFor={`groesse-${stage.id}`}
          >
            <img className="iconImage" src={size} alt="Größe" />
            <div className="standard_text">{stage.size}</div>
          </label>

          <div className="info-button" onClick={() => onSelectStage(stage)}>
            {" "}
            <img className="info-icon" src={info} alt="Info" />
          </div>
        </div>
      ))}

      {/* {isModalOpen && selectedScene && (
        <Modal
          onClose={() => setIsModalOpen(false)}
          title={selectedScene.stageName}
          content={selectedScene}
        />
      )} */}

      {/* Кнопка "Weiter" */}
      {isButtonActive && (
        <button
          type="button"
          onClick={handleNextButtonClick}
          className="anfragebtn"
        >
          Weiter
        </button>
      )}
    </div>
  );
};

export default StagePicker;
