// Footer.js
import React from 'react'
import band from '../img/band_white.svg';


const Footer = () => {
  return (
    <footer>
      <div className="band">
        <img src={band} alt="Mobile Bühne Band Logo" />
      </div>
          <div className="copyright">Copyright &copy; 2024 by mobile-bühne.at Gmbh</div>
    </footer>
  );
}

export default Footer;
