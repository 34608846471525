import React from "react";
import { useState } from "react";
import { useRef, useEffect } from "react";
import size from "../img/size.svg";
import sizeBlue from "../img/size_blue.svg";
import calendar from "../img/calendar2.svg";
import calendarBlue from "../img/calendar2_blue.svg";
import home from "../img/location.svg";
import homeBlue from "../img/location_blue.svg";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { Controller, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import "./MDatepicker.css";
import { useController } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import StagePicker from "../services/stagePicker";
import InfoModal from "./InfoModal";

registerLocale("de", de);

export default React.memo(function MForm({ onOptionsChange, onSelectStage }) {
  const [showDropdowns, setShow] = useState([false, false, false, false]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const closeDropdown = (i) => {
    setShow((prevShowDropdowns) => {
      const newShowDropdowns = [...prevShowDropdowns];
      newShowDropdowns[i] = false;
      return newShowDropdowns;
    });
  };

  const [isInfoModalOpen, setInfoModalOpen] = useState(false);

  const openInfoModal = () => {
    setInfoModalOpen(true);
  };

  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };
  const [buehnenart, setBuehnenart] = useState(null);
  const [termin, setTermin] = useState(null);
  const [location, setLocation] = useState({
    plz: "",
    ort: "",
    strasse: "",
    nummer: "",
  });
  const [kontakt, setKontakt] = useState({
    firma: "",
    plz: "",
    ort: "",
    strasse: "",
    nummer: "",
    vorname: "",
    nachname: "",
    tel: "",
    mail: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [thirdDate, setThirdDate] = useState(null);
  const [fourthDate, setFourthDate] = useState(null);

  const [secondStartDate, setSecondStartDate] = useState(null);
  const [secondEndDate, setSecondEndDate] = useState(null);

  const [startDate2, setStartDate2] = useState(null);

  const [comment, setComment] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
  });

  const [isFormValid, setFormValid] = useState(false);

  useEffect(() => {
    const isValid =
      location.plz && location.ort && location.strasse && location.nummer;
    setFormValid(isValid);
  }, [location]);

  const isAllOptionsSelected = buehnenart && startDate && endDate && location;
  const [showThirdDatePicker, setShowThirdDatePicker] = useState(false);

  const reset = () => {
    setShow([false, false, false, false]);
    setBuehnenart(null);
    setLocation({ plz: "", ort: "", strasse: "", nummer: "" });
    setKontakt({
      firma: "",
      plz: "",
      ort: "",
      strasse: "",
      nummer: "",
      person: "",
      tel: "",
      mail: "",
    });
    setStartDate(null);
    setEndDate(null);
    setThirdDate(null);
    setComment(null);
  };

  useEffect(() => {
    onOptionsChange({
      buehnenart,
      startDate,
      endDate,
      location,
      startDate2,
      thirdDate,
      fourthDate,
      comment,
    });
  }, [buehnenart, startDate, endDate, location, startDate2, thirdDate, fourthDate, comment, onOptionsChange]);

  const onNextDropdown = (index) => {
    setShow((prev) => {
      const newArr = [...prev];
      newArr[index + 1] = true;
      newArr[index] = false;
      return newArr;
    });

    if (index > 0) {
      setShow((prevShowDropdowns) => {
        const newShowDropdowns = [...prevShowDropdowns];
        newShowDropdowns[index - 1] = false;
        return newShowDropdowns;
      });
    }
  };

  const getErrorMessage = (field, label) => {
    if (errors[field] && errors[field].type === "required") {
      return `* Das Feld "${label}" ist verpflichtend`;
    }
    return null;
  };

  const onSubmit = async (data) => {
    try {
      const formattedStartDate = startDate
        ? formatToServerDate(startDate).slice(0, 16).replace("T", " ")
        : null;
      const formattedEndDate = endDate
        ? formatToServerDate(endDate).slice(0, 16).replace("T", " ")
        : null;
      const formattedStartDate2 = thirdDate
        ? formatToServerDate(thirdDate).slice(0, 16).replace("T", " ")
        : null;
      const formattedEndDate2 = fourthDate
        ? formatToServerDate(fourthDate).slice(0, 16).replace("T", " ")
        : null;

      const formattedData = {
        ...data,
        startDatum: formattedStartDate,
        endDatum: formattedEndDate,
        aufbauZeit: formattedStartDate2,
        abbauZeit: formattedEndDate2,
      };

      const response = await fetch(
        "https://admin.mobilebühne.at/items/orders",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formattedData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const messageDiv = document.getElementById("message");

      messageDiv.innerText =
        "Vielen Dank für Ihre Anfrage! Ihre Daten wurden erfolgreich übermittelt. Ein Mitarbeiter wird sich in Kürze bei Ihnen melden.";
      messageDiv.style.backgroundColor = "#4CAF50";
      messageDiv.style.display = "block";

      setTimeout(() => {
        window.location.href = window.location.href;

        messageDiv.style.display = "none";
      }, 4000);

      // reset();
    } catch (error) {
      console.error("Error:", error);

      const messageDiv = document.getElementById("message");

      messageDiv.innerText = "Error!";
      messageDiv.style.backgroundColor = "#FF5733";
      messageDiv.style.display = "block";

      setTimeout(() => {
        messageDiv.style.display = "none";
      }, 4000);
    }
  };

  const formatToServerDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const { field: startDateField } = useController({
    control,
    name: "startDatum",
    defaultValue: startDate,
  });

  const { field: endDateField } = useController({
    control,
    name: "endDatum",
    defaultValue: endDate,
  });

  //   MDatePicker Component
  // Overview
  // The MDatePicker component is a React component designed to facilitate the selection and management of date and time information for an event. It integrates the react-datepicker library for an interactive and user-friendly date and time picking experience.

  // State
  // maxTime: Represents the maximum time allowed for selecting a date and time.
  // minTimeForEndDate: Controls the minimum time available for selecting the end date.
  // Hooks and Side Effects
  // useEffect Hooks:
  // Two useEffect hooks are used for managing side effects related to the startDate, endDate, and thirdDate state variables.
  // The first useEffect updates maxTime based on the relationship between startDate and thirdDate.
  // The second useEffect updates minTimeForEndDate based on the relationship between startDate and endDate.
  // Date Picker Integration
  // Start Date Picker:

  // Allows users to select the start date with associated time.
  // Utilizes the react-datepicker library for date and time selection.
  // Validates and updates the selected start date and time.
  // Displays error messages for invalid selections.
  // End Date Picker:

  // Allows users to select the end date with associated time.
  // Utilizes the react-datepicker library for date and time selection.
  // Validates and updates the selected end date and time.
  // Manages minimum time for end date based on the selected start date.
  // Additional Date Picker (Auf- und Abbau Zeit):

  // Provides an additional date picker for setup and teardown times (thirdDate and fourthDate).
  // Synchronizes with the start and end dates.
  // Applies constraints based on the selected dates.
  // Conditional Rendering
  // Certain date and time picker components are conditionally rendered based on the existence of startDate, endDate, thirdDate, and fourthDate.
  // Information Modal
  // Includes an "Info" button that, when clicked, opens an information modal with additional details about the selected dates.
  // Error Handling
  // Displays error messages for mandatory date and time selections.

  const MDatePicker = () => {
    const { setValue } = useForm();
    const [maxTime, setMaxTime] = useState(new Date().setHours(23, 30, 0, 0));
    const [minTimeForEndDate, setMinTimeForEndDate] = useState(null);

    // useEffect(() => {
    //   if (!showThirdDatePicker) {
    //     setThirdDate(null);
    //     setFourthDate(null);
    //   }
    // }, [showThirdDatePicker]);

    useEffect(() => {
      if (startDate && thirdDate) {
        const startDateCopy = new Date(startDate);
        if (thirdDate.getDate() === startDateCopy.getDate()) {
          const twoHoursBeforeStartDate = new Date(
            startDate.getTime() - 2 * 60 * 60 * 1000
          );
          setMaxTime(twoHoursBeforeStartDate);
        } else {
          setMaxTime(new Date().setHours(23, 30, 0, 0));
        }
      } else {
        setMaxTime(new Date().setHours(23, 30, 0, 0));
      }
    }, [startDate, thirdDate]);

    useEffect(() => {
      if (startDate && endDate) {
        const isSameDay =
          startDate.getDate() === endDate.getDate() &&
          startDate.getMonth() === endDate.getMonth() &&
          startDate.getFullYear() === endDate.getFullYear();

        if (isSameDay) {
          const minTimeForEndDate = new Date(startDate);
          minTimeForEndDate.setHours(startDate.getHours() + 2);

          setMinTimeForEndDate(minTimeForEndDate);
        } else {
          setMinTimeForEndDate(null);
        }
      }
    }, [startDate, endDate]);

    return (
      <>
        <h3>
          <b>Veranstaltungszeit</b>
        </h3>
        <p className="formText">
        Verraten Sie uns bitte noch Datum und Uhrzeit Ihrer Veranstaltung:{" "}
        </p>{" "}
        <div className="mdatepicker-wrapper">
          <div>von:</div>
          <Controller
            rules={{ required: "Dieses Feld ist verpflichtend" }}
            control={control}
            name="startDatum"
            render={({ field }) => (
              <>
                <DatePicker
                  onChange={(date) => {
                    if (date) {
                      const startDateWithTime = new Date(date);

                      if (!field.value) {
                        startDateWithTime.setHours(7, 0, 0, 0);
                      }

                      field.onChange(startDateWithTime);
                      setStartDate(startDateWithTime);
                    } else {
                      field.onChange(null);
                      setStartDate(null);
                    }
                  }}
                  selected={field.value}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={
                    new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)
                  }
                  maxDate={endDate || null}
                  locale="de"
                  dateFormat="d. MMMM yyyy"
                  placeholderText="Datum wählen"
                  wrapperClassName="mdatepicker"
                  form="form_anfrage"
                />
                <DatePicker
                  selected={field.value}
                  onChange={(time) => {
                    if (time) {
                      const selectedTime = new Date(time);
                      const newDate = new Date(field.value || new Date());

                      // If the date is already selected, set the time
                      newDate.setHours(selectedTime.getHours());
                      newDate.setMinutes(selectedTime.getMinutes());

                      // Check if the date is selected
                      if (!field.value) {
                        // If the date is not selected, set it to the next day
                        newDate.setDate(newDate.getDate() + 1);
                      }

                      field.onChange(newDate);
                      setStartDate(newDate);
                    } else {
                      field.onChange(null);
                      setStartDate(null);
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  locale="de"
                  dateFormat="HH:mm"
                  timeCaption="Start"
                  placeholderText="Start"
                  // maxDate={endDate || null}
                  minTime={new Date().setHours(7, 0, 0, 0)}
                  maxTime={new Date().setHours(23, 30, 0, 0)}
                />
              </>
            )}
          />
        </div>
        {errors.startDatum && (
          <p role="alert" className="font-size100 alert alert-danger">
            {errors.startDatum.message}
          </p>
        )}
        <div className="mdatepicker-wrapper">
          <div>bis:</div>
          <Controller
            rules={{ required: "Dieses Feld ist verpflichtend" }}
            control={control}
            name="endDatum"
            render={({ field }) => (
              <>
                <DatePicker
                  onChange={(date) => {
                    if (date) {
                      const endDateWithTime = new Date(date);

                      endDateWithTime.setHours(9, 0, 0, 0);

                      field.onChange(endDateWithTime);
                      setEndDate(endDateWithTime);
                    } else {
                      field.onChange(null);
                      setEndDate(null);
                    }
                  }}
                  selected={field.value}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={
                    startDate ||
                    new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)
                  }
                  locale="de"
                  dateFormat="d. MMMM yyyy"
                  placeholderText="Datum wählen"
                  wrapperClassName="mdatepicker"
                />
                <DatePicker
                  selected={field.value}
                  onChange={(time) => {
                    if (time) {
                      const selectedTime = new Date(time);
                      const newDate = new Date(field.value || new Date());

                      newDate.setHours(selectedTime.getHours());
                      newDate.setMinutes(selectedTime.getMinutes());

                      if (!field.value) {
                        newDate.setDate(newDate.getDate() + 2);
                      }

                      field.onChange(newDate);
                      setEndDate(newDate);
                    } else {
                      field.onChange(null);
                      setEndDate(null);
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeCaption="Ende"
                  locale="de"
                  dateFormat="HH:mm"
                  placeholderText="Ende"
                  minDate={
                    new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)
                  }
                  minTime={minTimeForEndDate || new Date().setHours(9, 0, 0, 0)}
                  maxTime={new Date().setHours(23, 30, 0, 0)}
                />
              </>
            )}
          />
        </div>
        {errors.endDatum && (
          <p role="alert" className="font-size100 alert alert-danger">
            {errors.endDatum.message}
          </p>
        )}
        {/* <div className="checkBoxCont">
          <div className="checkBoxInfo"> Gewünschte Auf- und Abbau Zeit wählen</div>

          <input
            className="inputCheck"
            type="checkbox"
            id="showThirdDatePicker"
            checked={showThirdDatePicker}
            onChange={() => setShowThirdDatePicker(!showThirdDatePicker)}
          />
        </div> */}
        <div className="datePicker">
          {/* { (!startDate || !endDate) && (
            <p className="CheckBoxPar">
              Bitte wählen Sie zuerst das Start- und Enddatum aus.
            </p>
          )} */}

          <label htmlFor="showThirdDatePicker"></label>
          {startDate && endDate && (
            <>
              {/* <div className="infoModalContainer">
                <p> Sie wissen schon, wann die Bühne auf- und abgebaut werden soll? Wunderbar! Andernfalls überspringen Sie einfach diesen Punkt.</p>
                <button className="infoModalBtn" onClick={openInfoModal}>
                  Info
                </button>
              </div> */}
              <h3>
                <b>Auf- und Abbau</b>
              </h3>
              <span className="optional_label">optional</span>
              <p className="formText">
                Sie wissen schon, wann die Bühne auf- und abgebaut werden soll?
                Wunderbar! Andernfalls überspringen Sie einfach diesen Punkt.{" "}
              </p>{" "}
              <div className="thirdDp">
                <div>Aufbaubeginn:</div>
                <DatePicker
                  id="thirdDate"
                  onChange={(date) => {
                    if (date) {
                      const startDateWithTime = new Date(date);

                      if (!thirdDate) {
                        startDateWithTime.setHours(5, 0, 0, 0);
                      }

                      setThirdDate(startDateWithTime);
                      setValue("startDatum2", startDateWithTime);
                    } else {
                      setThirdDate(null);
                      setValue("startDatum2", null);
                    }
                  }}
                  selected={thirdDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={
                    startDate
                      ? new Date(
                          startDate.getTime() +
                            (startDate.getDate() === new Date().getDate() + 1
                              ? -1
                              : startDate.getDate() === new Date().getDate() + 2
                              ? -2
                              : startDate.getDate() === new Date().getDate() + 3
                              ? -3
                              : -4) *
                              24 *
                              60 *
                              60 *
                              1000
                        )
                      : null
                  }
                  maxDate={
                    startDate
                      ? new Date(startDate.getTime() - 2 * 60 * 60 * 1000)
                      : null
                  }
                  locale="de"
                  dateFormat="d. MMMM yyyy"
                  placeholderText="Datum wählen"
                  wrapperClassName="mdatepicker"
                  form="form_anfrage"
                />
                <DatePicker
                  selected={thirdDate}
                  onChange={(date) => {
                    setThirdDate(date);
                    setValue("startDatum2", date);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  locale="de"
                  dateFormat="HH:mm"
                  timeCaption="Start"
                  placeholderText="Aufbau start Zeit"
                  minTime={new Date().setHours(5, 0, 0, 0)}
                  maxTime={maxTime}
                />
              </div>
              <div className="fourthDp">
                <div>Abbaubeginn:</div>
                <DatePicker
                  onChange={(date) => {
                    if (date) {
                      const fourthDateWithTime = new Date(date);

                      if (!fourthDate) {
                        fourthDateWithTime.setHours(8, 0, 0, 0);
                      }

                      setFourthDate(fourthDateWithTime);
                      setValue("endDatum2", fourthDateWithTime);
                    } else {
                      setFourthDate(null);
                      setValue("endDatum2", null);
                    }
                  }}
                  selected={fourthDate}
                  selectsEnd
                  startDate={endDate}
                  endDate={fourthDate}
                  minDate={endDate}
                  maxDate={
                    endDate
                      ? new Date(endDate.getTime() + 2 * 24 * 60 * 60 * 1000)
                      : null
                  }
                  locale="de"
                  dateFormat="d. MMMM yyyy"
                  placeholderText="Datum wählen"
                  wrapperClassName="mdatepicker"
                />
                <DatePicker
                  selected={fourthDate}
                  onChange={(time) => {
                    if (time) {
                      const selectedTime = new Date(time);
                      const endDateWithOneDay = new Date(endDate);
                      endDateWithOneDay.setDate(endDate.getDate() + 1);

                      endDateWithOneDay.setHours(selectedTime.getHours());
                      endDateWithOneDay.setMinutes(selectedTime.getMinutes());

                      setFourthDate(endDateWithOneDay);
                      setValue("endDatum2", endDateWithOneDay);
                    } else {
                      setFourthDate(null);
                      setValue("endDatum2", null);
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeCaption="Ende"
                  locale="de"
                  dateFormat="HH:mm"
                  placeholderText="Abbau start Zeit"
                  minTime={new Date().setHours(8, 0, 0, 0)}
                  maxTime={new Date().setHours(23, 30, 0, 0)}
                />
              </div>
            </>
          )}
          {isInfoModalOpen && <InfoModal onClose={closeInfoModal} />}
        </div>
      </>
    );
  };

  // ********** END OF MDatePicker **********

  function onClickDropdown(i) {
    setShow((prevShowDropdowns) => {
      const newShowDropdowns = [...prevShowDropdowns];

      // Close all other blocks
      for (let j = 0; j < newShowDropdowns.length; j++) {
        if (j !== i) {
          newShowDropdowns[j] = false;
        }
      }

      newShowDropdowns[i] = !newShowDropdowns[i];
      return newShowDropdowns;
    });

    setActiveDropdown(i);

    // const targetElement = document.getElementById(`dropdown-${i}`);
    // if (targetElement) {
    //   setTimeout(() => {
    //     targetElement.scrollIntoView({ behavior: "smooth" });
    //   }, 200);
    // }
  }
  

  return (
    <>
      <form
        id="form_anfrage"
        className="content_margin_sides_float floating_section_booking"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="booking_dropdown">
          <button
            className="firstButton"
            type="button"
            onClick={() => {
              onClickDropdown(0);
            }}
          >
            <img className="image" src={size} alt="Größe" />
            <div className="booking_dropdown_text">
              <p className="formTextHolder">
                {buehnenart ? buehnenart : "Größe"}
              </p>
            </div>
          </button>

          <div
            id="dropdown-0"
            className={(showDropdowns[0] ? "" : "hidden ") + "hidden_dropdown"}
          >
            <h3>
              <b>Bühnengröße</b>
            </h3>
            <p
              className="formText"
              // className="divider_underline"
            >
              Wählen Sie die passende Bühne für Ihre Veranstaltung:
            </p>
            <StagePicker
              register={register}
              setBuehnenart={setBuehnenart}
              onClickDropdown={onClickDropdown}
              onNextDropdown={() => onNextDropdown(0)}
              onSelectStage={onSelectStage} 
            />
            {/* <div className="buehnenpicker-wrapper">
            <div className="">
              <input
                type="radio"
                className=""
                value="4 x 3 m"
                id="groesse-4x3"
                key="1"
                {...register("buehnenart", { required: "Bitte einen Bühnengröße auswählen" })}
                onClick={(e) => { setBuehnenart(e.target.value) }} />

              <label className="option divider_underline" htmlFor="groesse-4x3"><img src={size} alt="Größe" /> <div className="standard_text">4 x 3 m</div><div className="text_small">(Breite x Tiefe)</div></label>
            </div>
            <div className="">
              <input
                type="radio"
                value="6 x 4 m"
                id="groesse-6x4"
                key="2"
                {...register("buehnenart", { required: "Bitte einen Bühnengröße auswählen" })}
                onClick={(e) => { setBuehnenart(e.target.value) }} />
              <label className="option divider_underline" htmlFor="groesse-6x4"><img src={size} alt="Größe" /> <div className="standard_text">6 x 4 m</div><div className="text_small">(Breite x Tiefe)</div></label>
            </div>
            <div className="">
              <input
                type="radio"
                className=""
                value="8 x 6 m"
                id="groesse-8x6"
                key="3"
                {...register("buehnenart", { required: "Bitte gewünschte Bühnengröße auswählen" })}
                onClick={(e) => { setBuehnenart(e.target.value) }} />
              <label className="option divider_underline" htmlFor="groesse-8x6"><img src={size} alt="Größe" /> <div className="standard_text">8 x 6 m</div><div className="text_small">(Breite x Tiefe)</div></label>
            </div>
          </div> */}
            {errors.buehnenart && (
              <p role="alert" className="font-size100 alert alert-danger">
                {errors.buehnenart.message}
              </p>
            )}
          </div>
        </div>

        {/* TERMIN SECTION */}

        <div className="booking_dropdown">
          <button
            type="button"
            onClick={() => {
              onClickDropdown(1);
            }}
          >
            {/* disabled={!startDate || !endDate} */}
            <img className="image" src={home} alt="Einsatz-Adresse" />
            {/* style={{ filter: (!startDate || !endDate) ? 'grayscale(100%)' : '' }} */}
            <div className="booking_dropdown_text">
              <p className="formTextHolder">
                {location?.ort && location?.plz
                  ? `${location.plz}, ${location.ort}`
                  : "Location"}
              </p>
            </div>
          </button>

          <div
            id="dropdown-1"
            className={(showDropdowns[1] ? "" : "hidden ") + "hidden_dropdown"}
          >
            <h3>
              <b>Veranstaltungsort</b>
            </h3>
            <p className="formText">
              Teilen Sie uns mit, wo Ihre Veranstaltung stattfindet:
            </p>{" "}
            <div className="option divider_underline">
              <label className="standard_text" htmlFor="plz">
                PLZ
              </label>
              <input
                className="inputDropdown"
                type="text"
                id="plz"
                name="plz"
                maxLength="4"
                onInput={(e) => {
                  const inputValue = e.target.value.replace(/\D/g, ""); // Remove everything except digits
                  setLocation({ ...location, plz: inputValue });
                }}
                onKeyPress={(e) => {
                  if (isNaN(e.key)) {
                    e.preventDefault(); // Prevent input of non-numeric characters
                  }
                }}
                {...register("einsatz_plz", {
                  required: "Dieses Feld ist verpflichtend",
                  pattern: /^[0-9]{4}$/, // Regular expression for four digits
                })}
              />
            </div>
            {getErrorMessage("einsatz_plz", "PLZ") && (
              <p role="alert" className="font-size100 alert alert-danger">
                {getErrorMessage("einsatz_plz", "PLZ")}
              </p>
            )}
            <div className="option divider_underline">
              <label className="standard_text" htmlFor="ort">
                Ort
              </label>
              <input
                className="inputDropdown"
                type="text"
                id="ort"
                name="ort"
                onKeyPress={(e) => {
                  if (!isNaN(parseFloat(e.key)) && isFinite(e.key)) {
                    e.preventDefault();
                  }
                }}
                {...register("einsatz_ort", {
                  onChange: (e) => {
                    setLocation({ ...location, ort: e.target.value });
                  },
                  required: "Dieses Feld ist verpflichtend",
                })}
              />
            </div>
            {getErrorMessage("einsatz_ort", "Ort") && (
              <p role="alert" className="font-size100 alert alert-danger">
                {getErrorMessage("einsatz_ort", "Ort")}
              </p>
            )}
            <div className="option divider_underline">
              <label className="standard_text" htmlFor="einsatz-strasse">
                Strasse
              </label>
              <input
                className="inputDropdown"
                type="text"
                id="einsatz-strasse"
                name="einsatz-strasse"
                onKeyPress={(e) => {
                  if (!isNaN(parseFloat(e.key)) && isFinite(e.key)) {
                    e.preventDefault();
                  }
                }}
                {...register("einsatz_strasse", {
                  onChange: (e) => {
                    setLocation({ ...location, strasse: e.target.value });
                  },
                  required: "Dieses Feld ist verpflichtend",
                })}
              />
            </div>
            {getErrorMessage("einsatz_strasse", "Strasse") && (
              <p role="alert" className="font-size100 alert alert-danger">
                {getErrorMessage("einsatz_strasse", "Strasse")}
              </p>
            )}
            <div className="option divider_underline_last">
              <label className="standard_text" htmlFor="einsatz-nummer">
                Nummer
              </label>
              <input
                className="inputDropdown"
                type="number"
                id="einsatz-nummer"
                name="einsatz-nummer"
                {...register("einsatz_nummer", {
                  onChange: (e) => {
                    setLocation({ ...location, nummer: e.target.value });
                  },
                  required: "Dieses Feld ist verpflichtend",
                })}
              />
            </div>
            {getErrorMessage("einsatz_nummer", "Nummer") && (
              <p role="alert" className="font-size100 alert alert-danger">
                {getErrorMessage("einsatz_nummer", "Nummer")}
              </p>
            )}
            {/* "Weiter" */}
            {isFormValid && (
              <button
                className="anfragebtn1"
                type="button"
                onClick={() => {
                  onNextDropdown(1);
                }}
              >
                Weiter
              </button>
            )}
          </div>
        </div>

        <div className="booking_dropdown">
          <button
            type="button"
            onClick={() => {
              onClickDropdown(2);
            }}
          >
            <img className="image" src={calendar} alt="Kalender" />
            {/* style={{ filter: buehnenart ? '' : 'grayscale(100%)' }} */}
            <div className="booking_dropdown_text">
              <p className="formTextHolder">
                {startDate || endDate
                  ? (startDate
                      ? startDate.toLocaleString("de", {
                          month: "numeric",
                          day: "numeric",
                        })
                      : "") +
                    " - " +
                    (endDate
                      ? endDate.toLocaleString("de", {
                          month: "numeric",
                          day: "numeric",
                        })
                      : "")
                  : "Termin"}
              </p>
            </div>
          </button>

          <div
            id="dropdown-2"
            className={(showDropdowns[2] ? "" : "hidden ") + "hidden_dropdown"}
          >
            {/* ******* */}
            <MDatePicker></MDatePicker>
            {/* ******* */}

            {isAllOptionsSelected && isFormValid && (
              <button
                className="anfragebtn"
                type="button"
                onClick={() => {
                  onNextDropdown(2);
                }}
              >
                Weiter
              </button>
            )}
          </div>
        </div>

        <div className="booking_dropdown lastButton">
          <button
            type="button"
            className={`pink${
              !location || !buehnenart || !startDate || !endDate
                ? " disabled"
                : ""
            }`}
            onClick={() => {
              onClickDropdown(3);
            }}
            disabled={
              !location.nummer ||
              !location.ort ||
              !location.plz ||
              !location.strasse ||
              !buehnenart ||
              !startDate ||
              !endDate
            }
          >
            <div className="booking_dropdown_text_anfragen">
              <p className="formTextHolder">Anfragen</p>
            </div>
          </button>
          <div
            id="dropdown-3"
            className={
              (showDropdowns[3] ? "" : "hidden ") + "hidden_dropdown left"
            }
          >
            <div className="anfrageAuswahl">
              {isAllOptionsSelected && (
                <div className="padding_bottom_wrapper auswahlTabelle">
                  <h3>
                    <b>Sie haben gewählt:</b>
                  </h3>
                  <div className="infoAuswahl">
                    <div className="infoSize">
                      <img src={sizeBlue} alt="Bühnengröße" />
                      <div>
                      Bühnengröße: <br /><b>{buehnenart}</b>{" "}
                      </div>
                      {errors.buehnenart && (
                        <span
                          role="alert"
                          className="font-size100 alert alert-danger"
                        >
                          &#10071;
                        </span>
                      )}
                    </div>
                    <div className="infoOrt">
                      <img src={homeBlue} alt="Veranstaltungsort" />
                      <div>
                      Veranstaltungsort:{" "}
                      <br />
                      <b>
                        {location.strasse} {location.nummer}, {location.plz}{" "}
                        {location.ort}
                      </b>
                      </div>
                      {(errors.einsatz_nummer ||
                        errors.einsatz_ort ||
                        errors.einsatz_plz ||
                        errors.einsatz_strasse) && (
                        <span
                          role="alert"
                          className="font-size100 alert alert-danger"
                        >
                          &#10071;
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="infoZeit">
                    <img src={calendarBlue} alt="Veranstaltungsdatum" />
                    <div className="infoAufbau">
                      Aufbau:<br />
                      <b>
                      {thirdDate
                        ? thirdDate.toLocaleString("de", {
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : "nicht ausgewählt"}
                        </b>
                    </div>
                    <div className="infoEventStart">
                      Eventstart:
                      <br />
                      <b>
                        {startDate?.toLocaleString("de", {
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </b>{" "}
                    </div>
                    <div className="infoEventEnde">
                      Eventende:
                      <br />
                      <b>
                        {endDate?.toLocaleString("de", {
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </b>{" "}
                    </div>
                    {(errors.startDatum || errors.endDatum) && (
                      <span
                        role="alert"
                        className="font-size100 alert alert-danger"
                      >
                        &#10071;
                      </span>
                    )}
                    <div className="infoAbbau">
                      Abbau:<br />
                      <b>
                      {fourthDate
                        ? fourthDate.toLocaleString("de", {
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : "nicht ausgewählt"}
                        </b>
                    </div>
                    {(errors.startDatum || errors.endDatum) && (
                      <span
                        role="alert"
                        className="font-size100 alert alert-danger"
                      >
                        &#10071;
                      </span>
                    )}
                  </div> 
                </div>
              )}
            </div>
            <div className="padding_bottom_wrapper">
              <div className="anfragen">
                <div className="anfrageLeft">
                  <h3>Ihre Kontaktdaten</h3>
                  <div className="option divider_underline">
                    <label className="standard_text" htmlFor="firma">
                      Firma
                    </label>
                    <input
                      className="inputDropdown"
                      type="text"
                      id="firma"
                      name="firma"
                      onChange={(e) => {
                        setKontakt({ ...kontakt, firma: e.target.value });
                      }}
                      {...register("firmenname")}
                    />
                  </div>
                  {/* <h3>Adresse</h3> */}
                  <div className="option divider_underline">
                    <label className="standard_text" htmlFor="firma_plz">
                      PLZ
                    </label>
                    <input
                      className="inputDropdown"
                      type="number"
                      id="firma_plz"
                      name="firma_plz"
                      onChange={(e) => {
                        setKontakt({ ...kontakt, plz: e.target.value });
                      }}
                      {...register("firma_plz")}
                    />
                  </div>
                  <div className="option divider_underline">
                    <label className="standard_text" htmlFor="firma_ort">
                      Ort
                    </label>
                    <input
                      className="inputDropdown"
                      type="text"
                      id="firma_ort"
                      name="firma_ort"
                      onChange={(e) => {
                        setKontakt({ ...kontakt, ort: e.target.value });
                      }}
                      {...register("firma_ort")}
                    />
                  </div>
                  <div className="option divider_underline">
                    <label className="standard_text" htmlFor="firma_strasse">
                      Strasse
                    </label>
                    <input
                      className="inputDropdown"
                      type="text"
                      id="firma_strasse"
                      name="firma_strasse"
                      onChange={(e) => {
                        setKontakt({ ...kontakt, strasse: e.target.value });
                      }}
                      {...register("firma_strasse")}
                    />
                  </div>
                  <div className="option divider_underline">
                    <label className="standard_text" htmlFor="firma_hnummer">
                      Hausnummer
                    </label>
                    <input
                      className="inputDropdown"
                      type="number"
                      id="firma_hnummer"
                      name="firma_hnummer"
                      onChange={(e) => {
                        setKontakt({ ...kontakt, nummer: e.target.value });
                      }}
                      {...register("firma_hnummer")}
                    />
                  </div>
                  <div>
                    <div className="form-check">
                      <input
                        className="inputCheck"
                        type="checkbox"
                        {...register("datenspeicherung", { required: true })}
                        id="datenspeicherung"
                        name="datenspeicherung"
                        value="datenspeicherung"
                      />
                      <label
                        htmlFor="datenspeicherung"
                        className="form-label required"
                      >
                        Datenschutzerklärung
                      </label>
                    </div>
                    {errors.datenspeicherung?.type === "required" && (
                      <p
                        role="alert"
                        className="font-size100 alert alert-danger"
                      >
                        Verpflichtendes Feld
                      </p>
                    )}
                  </div>
                </div>

                <div className="anfrageRight">
                  <h3>Ansprechpartner</h3>
                  <div className="option divider_underline">
                    <label className="standard_text" htmlFor="ansprechperson">
                      Vorname
                    </label>
                    <input
                      className="inputDropdown"
                      type="text"
                      id="ansprechperson"
                      name="ansprechperson"
                      onChange={(e) => {
                        setKontakt({ ...kontakt, vorname: e.target.value });
                      }}
                      {...register("vorname", {
                        required: "Dieses Feld ist verpflichtend",
                      })}
                    />
                  </div>
                  {errors.vorname && (
                    <p role="alert" className="font-size100 alert alert-danger">
                      {errors.vorname.message}
                    </p>
                  )}

                  <div className="option divider_underline">
                    <label
                      className="standard_text"
                      htmlFor="ansprechperson_nachname"
                    >
                      Nachname
                    </label>
                    <input
                      className="inputDropdown"
                      type="text"
                      id="ansprechperson_nachname"
                      name="ansprechperson_nachname"
                      onChange={(e) => {
                        setKontakt({ ...kontakt, nachname: e.target.value });
                      }}
                      {...register("nachname", {
                        required: "Dieses Feld ist verpflichtend",
                      })}
                    />
                  </div>
                  {errors.nachname && (
                    <p role="alert" className="font-size100 alert alert-danger">
                      {errors.nachname.message}
                    </p>
                  )}
                  <div className="option divider_underline">
                    <label className="standard_text" htmlFor="telnummer">
                      Telefon
                    </label>
                    <input
                      className="inputDropdown"
                      type="tel"
                      id="telnummer"
                      name="telnummer"
                      onChange={(e) => {
                        setKontakt({ ...kontakt, tel: e.target.value });
                      }}
                      {...register("tel", {
                        required: "Dieses Feld ist verpflichtend",
                      })}
                      autoComplete="tel"
                    />
                  </div>
                  {errors.tel && (
                    <p role="alert" className="font-size100 alert alert-danger">
                      {errors.tel.message}
                    </p>
                  )}
                  <div className="option divider_underline">
                    <label className="standard_text" htmlFor="email">
                      E-Mail
                    </label>
                    <input
                      className="inputDropdown"
                      type="email"
                      id="email"
                      name="email"
                      onChange={(e) => {
                        setKontakt({ ...kontakt, mail: e.target.value });
                      }}
                      {...register("email", {
                        required: "Dieses Feld ist verpflichtend",
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/,
                          message: "Muss eine gültige Email Adresse sein!",
                        },
                      })}
                      autoComplete="email"
                    />
                  </div>
                  <div className="option flexCol">
                    <label className="comment" htmlFor="comment">
                      Kommentar
                    </label>
                    <textarea
                      className="textArea"
                      id="comment"
                      name="comment"
                      rows="3" 
                      onChange={(e) => {
                        setKontakt({ ...kontakt, comment: e.target.value });
                      }}
                      {...register("comment")}
                    ></textarea>
                  </div>
                  {errors.email && (
                    <p role="alert" className="font-size100 alert alert-danger">
                      {errors.email.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <button className="anfragebtn_last" type="submit">
              <span className="text">Anfrage senden</span>
              <span className="shimmer"></span>
            </button>
          </div>
        </div>
      </form>
    </>
  );
});
