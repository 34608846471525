import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import Modal from "./modal";
import "../Main.css";
import StagesComponent from "../services/directus";
import MForm from "./MForm";



const API_BASE_URL = "https://admin.mobilebühne.at";

export default function User() {
  const [showSecondDatePicker, setShowSecondDatePicker] = useState(false);

  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [savedStartDate2, setSavedStartDate2] = useState(null);
    const storedToken = localStorage.getItem("refreshToken");
    const [userName, setUserName] = useState("");

    useEffect(() => {
        // Fetch user information using access token
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          axios.get(`${API_BASE_URL}/users/me`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(response => {
            // Assuming the 'first_name' is available in the 'data' object
            const firstName = response.data.data.first_name;
            setUserName(firstName);
            // Assuming the user ID is in the 'id' field of the response data
            const userId = response.data.data.id;
            // Now you have the user ID and can use it as needed
            // Make another request if you need more detailed user information
          })
          .catch(error => {
            console.error("Error fetching user information:", error);
          });
        }
      }, []);
      
    
   

   
    
    
    
    
    
    
    
    
      



  const [selectedOptions, setSelectedOptions] = useState({
    buehnenart: null,
    startDate: null,
    endDate: null,
    StartDatum2: null,
    thirdDate: null,
    location: {
      plz: "",
      ort: "",
      strasse: "",
      nummer: "",
    },
  });

  const [formErrors, setFormErrors] = useState({
    buehnenart: null,
    startDatum: null,
    endDatum: null,
    StartDatum2: null,
    thirdDate: null,
    einsatz_nummer: null,
    einsatz_ort: null,
    einsatz_plz: null,
    einsatz_strasse: null,
  });

  const handleOptionsChange = useCallback(
    (options) => {
      setSelectedOptions((prevOptions) => {
        console.log("Current selectedOptions:", prevOptions);
        console.log("Options to set:", options);

        return {
          ...prevOptions,
          ...options,
        };
      });
    },
    [setSelectedOptions, setShowSecondDatePicker]
  );

  const handleSquareClick = (selectedStage) => {
    if (selectedStage) {
      setIsModalOpen(true);
      setModalContent({ title: "", content: selectedStage });
    } else {
      setIsModalOpen(false);
      setModalContent(null);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };



  return (
    <>

<div id="message" className="fixed-message"></div>

        <h1>Hi {userName}!!! </h1>

      <section className="gradient_bg">
        <MForm onOptionsChange={handleOptionsChange} />
      </section>

      <div>
        <StagesComponent onSelectStage={handleSquareClick} />
      </div>

      {isModalOpen && (
        <Modal
          onClose={handleCloseModal}
          title={modalContent.title}
          content={modalContent.content}
        />
      )}
    </>
  );
}
