import React from "react";
import { Link } from "react-router-dom";
import logo from "../img/mb_logo.svg";

const Header = () => {
  const scrollToStagePicker = () => {
    const stagePickerElement = document.getElementById("buehnen");
    if (stagePickerElement) {
      stagePickerElement.scrollIntoView({ behavior: "smooth", block: 'center' });
    }
  };

  const scrollToKontakt = () => {
    const stagePickerElement = document.getElementById("kontakt");
    if (stagePickerElement) {
      stagePickerElement.scrollIntoView({ behavior: "smooth", block: 'center' });
    }
  };

  const scrollToImpressum = () => {
    const stagePickerElement = document.getElementById("impressum");
    if (stagePickerElement) {
      stagePickerElement.scrollIntoView({ behavior: "smooth", block: 'center' });
    }
  };

  return (
      <header>
        <nav>
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <ul>
            <li>
              {/* <Link to="/register">Registration</Link> */}
            </li>
            <li>
              <a href="#stagePicker" className="stagesLink">Bühnen</a>
            </li>
            <li>
              <a href="#kontakt" className="stagesLink">Kontakt</a>
            </li>
            <li>
              <a href="#impressum" className="stagesLink">Impressum</a>
            </li>
          </ul>
          <div class="hamburger "><span class="bar"></span><span class="bar"></span><span class="bar"></span></div>
        </nav>
      </header>
  );
};

export default Header;
