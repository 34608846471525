import React, { useState } from "react";
import logo from "../img/mb_logo.svg";
import { Link } from "react-router-dom";

const Header = () => {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true"
  );

  const handleLogout = () => {
    localStorage.removeItem("authenticated");
    setAuthenticated(false);
    window.location.href = "/"; // Указывайте путь, куда хотите перенаправить пользователя
  };

  return (
    <header>
      <nav>

        <div className="logo">
          <a href="/user"> <img src={logo} alt="Logo" /></a>
        </div>

        <ul>
          <li>
          <a href="/about">Information</a>
          </li>
          <li>
          <button onClick={handleLogout}>Logout</button>

          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
