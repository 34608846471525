import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import Modal from "./components/modal";
import "./Main.css";
import band from "./img/band_white.svg";
import size_white from "./img/size_white.svg";
import MForm from "./components/MForm";
import stage from "./img/stage-default.jpg";
import stage2 from "./img/stage2.png";
import info from "../src/img/info_white.svg";
import measure from "./img/measureBG2.svg";
import weight from "./img/weight.svg";
import time from "./img/time-lapse2.svg";
import people from "./img/people.svg";
import mail from "./img/mail.svg";
import phone from "./img/phone.svg";
import StagesComponent from "./services/directus";
import Footer from "./components/footer";
import LoginForm from "./components/register";
import CreateUserForm from "./components/register";
import Register from "./components/register";
import GridAnimation from "./components/GridAnimation";

const API_BASE_URL = "https://admin.mobile-bühne.at";

export default function Main() {
  const [showSecondDatePicker, setShowSecondDatePicker] = useState(false);

  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState(null);
  const [savedStartDate2, setSavedStartDate2] = useState(null);

  const [selectedOptions, setSelectedOptions] = useState({
    buehnenart: null,
    startDate: null,
    endDate: null,
    StartDatum2: null,
    thirdDate: null,
    location: {
      plz: "",
      ort: "",
      strasse: "",
      nummer: "",
    },
  });

  const [formErrors, setFormErrors] = useState({
    buehnenart: null,
    startDatum: null,
    endDatum: null,
    StartDatum2: null,
    thirdDate: null,
    einsatz_nummer: null,
    einsatz_ort: null,
    einsatz_plz: null,
    einsatz_strasse: null,
  });

  // useEffect(() => {
  //   if (selectedOptions.endDate !== undefined && selectedOptions.endDate !== null) {
  //     setShowSecondDatePicker(true);
  //   } else {
  //     setShowSecondDatePicker(false);
  //   }
  // }, [selectedOptions.endDate]);

  const handleOptionsChange = useCallback(
    (options) => {
      setSelectedOptions((prevOptions) => {
        console.log("Current selectedOptions:", prevOptions);
        console.log("Options to set:", options);

        // const { endDatum, /* другие опции */ } = options;

        // if (endDatum !== undefined && endDatum !== null) {
        //   setShowSecondDatePicker(true);
        // } else {
        //   setShowSecondDatePicker(false);
        // }

        return {
          ...prevOptions,
          ...options,
        };
      });
    },
    [setSelectedOptions, setShowSecondDatePicker]
  );

  // const handleSquareClick = (selectedStage) => {
  //   // console.log('Clicked stage:', selectedStage);
  //   if (selectedStage) {
  //     setIsModalOpen(true);
  //     setModalContent({ title: "", content: selectedStage });
  //   } else {
  //     setIsModalOpen(false);
  //     setModalContent(null);
  //   }
  // };

  const handleSquareClick = (selectedStage) => {
    if (selectedStage) {
      setIsModalOpen(true);
      setModalContent({ title: "", content: selectedStage });
  
      // Используем setTimeout, чтобы гарантировать, что модальное окно уже отображается перед прокруткой
      setTimeout(() => {
        const modalElement = document.querySelector('.modalCont'); // Замените на ваш класс модального окна
        if (modalElement) {
          modalElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    } else {
      setIsModalOpen(false);
      setModalContent(null);
    }
  };
  

  const handleRegistration = (userData) => {
    console.log('User registered:', userData);
    // Другие действия после успешной регистрации
  };

  // Modal
  // useEffect(() => {
  //   if (isModalOpen) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "unset";
  //   }
  // }, [isModalOpen]);

  // useEffect(() => {
  //   // console.log('isModalOpen effect:', isModalOpen);
  //   if (isModalOpen) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'unset';
  //   }
  // }, [isModalOpen]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
    {/* <div>
      <h1>Registration Page</h1>
      <Register onRegister={handleRegistration} />
    </div> */}

      <div id="message" className="fixed-message"></div>
      <section className="section_1_blue black_bg">
        <div className="content_margin_sides">
          <h1>
            Mieten Sie Ihre <br /> <b>Mobile</b> <b>TrailerBühne</b>
          </h1>
          

          <h2>direkt bei Österreichs größtem Anbieter.</h2>

       

        </div>
        
      </section>
      <section className="gradient_bg">
      <MForm onOptionsChange={handleOptionsChange} onSelectStage={handleSquareClick}/>

       
      </section>

      <div id="stagePicker" className="stages">
        <StagesComponent onSelectStage={handleSquareClick} />
      </div>

      <section id="kontakt">
        <div className="content_margin_sides">
          <h3>Kontakt</h3>
          <h4>Bei speziellen Anfragen können Sie uns auch direkt hier erreichen:</h4>
          <a href="tel:+432236397080" target="_blank"><img src={phone} alt="Telefon" /> +43 2236 39 70 80</a><br />
          <a href="mailto:office@mobile-buehne.at" target="_blank"><img src={mail} alt="E-Mail" /> office@mobile-buehne.at</a>
        </div>
      </section>

      <section id="impressum">
        <div className="content_margin_sides">
          <h3>Impressum</h3>

          <p><strong>mobile-bühne.at GmbH</strong><br />
          Rheinboldtstraße 12<br />
          2362 Biedermannsdorf</p>
          <p>FN 513691d HG Wr. Neustadt<br />
          UID ATU 74608347</p>
        </div>
      </section>

      {/* <Register></Register> */}

      

      {/* Modal */}
      {isModalOpen && (
        <Modal
          onClose={handleCloseModal}
          title={modalContent.title}
          content={modalContent.content}
        />
      )}
    </>
  );
}
