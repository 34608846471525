// InfoModal.js

import React from 'react';

const InfoModal = ({ onClose }) => {
  return (
    <div className="infoModal">
      <div className="modalOverlay" onClick={onClose}></div>
      <div className="modalContent">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Information</h2>
        <p>TEXT</p>
        <h3>Info</h3>
      </div>
    </div>
  );
};

export default InfoModal;
