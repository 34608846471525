// App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Main from "./Main";
import Header from "./components/header";
import Footer from "./components/footer";
import Register from "./components/register";
import InformationComponent from "./components/information";
import User from "./components/user";
import PrivateRoute from "./components/PrivateRoute";
import UserHeader from "./components/userHeader";

function App() {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true"
  );
    
  const handleLogout = () => {
    localStorage.removeItem("authenticated");
    setAuthenticated(false);
  };
  

  const handleRegister = (data) => {
    console.log("Registration successful:", data);
    localStorage.setItem("authenticated", "true");
    setAuthenticated(true);
  };
  

  return (
    <Router>
      <div className="App">
        <div className="header-buffer"></div>
      {authenticated ? <UserHeader /> : <Header />}
                {/* <button onClick={handleLogout}>Logout</button> */}

        <Routes>
          <Route path="/" element={<Main />} />
          <Route
            path="/register"
            element={<Register onRegister={handleRegister} />}
          />
          <Route path="/about" element={<InformationComponent />} />
          {/* Используйте PrivateRoute для /user */}
          <Route
            path="/user/*"
            element={<PrivateRoute element={User} authenticated={authenticated} />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
