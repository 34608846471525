import React, { useState, useEffect } from 'react';
import axios from 'axios';
import size_white from '../img/size_white.svg';
import info from '../img/info.svg';
import band from '../img/band_white.svg';
import size from '../img/size.svg'

const API_BASE_URL = 'https://admin.mobile-bühne.at';

const selectedStage = null;  
const fetchStages = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/items/stages`);
    // ?fields=*.*.*
    return response.data;
  } catch (error) {
    console.error('Error fetching stages from Directus:', error);
  }
};

const StageBlock = ({ stage, onSelectStage }) => (
  <div className="square blue_bg" onClick={() => onSelectStage(stage)}>
    <div className='squareInfo'>
      <div>
        <img className='square-img' src={size_white} alt="Größe" />
      </div>
      <h3 className='h3Size'>{stage.size}</h3>
      <p>(Breite x Tiefe)</p>
    </div>
    <span className='info-iconContainer'>
      <img className="info-icon" src={info} alt="Info" />
    </span>
  </div>
);

export const StagesComponent = ({ onSelectStage }) => {
  const [stages, setStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStagesAndSetState = async () => {
      try {
        const stagesData = await fetchStages();
        setStages(stagesData.data);
  
        if (stagesData.data.length > 0 && !selectedStage) {
          const initialStage = stagesData.data[0];
          setSelectedStage(initialStage);
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchStagesAndSetState();
  }, [selectedStage]);
  
  useEffect(() => {
    // console.log('Component updated', stages);
  }, [stages]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!stages.length) {
    return <p>No stages found</p>;
  }

  return (
    <section className="section_stage_sizes blue_bg">
      <div className="content_margin_sides" id='buehnen'>
        <h3>Unser Angebot</h3>
        <h4>Wir bieten Ihnen folgende Bühnen zur Vermietung an:</h4>

        <div className="stage_size_wrapper">
          {stages.map((stage) => (
            <StageBlock key={stage.id} stage={stage} onSelectStage={(selectedStage) => {
              setSelectedStage(selectedStage);
              onSelectStage(selectedStage);
            }} />
          ))}
        </div>

      </div>
      
      {/* <div className="band_floor"></div> */}
    </section>
  );
};

export default StagesComponent;



