import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Gallery = ({ sceneId }) => {
  
  const [zoomed, setZoomed] = useState(false);
  const [slider, setSlider] = useState(null);
  const handleMouseWheel = (e) => {
    if (slider && slider.innerSlider) {
      const sliderNode = slider.innerSlider.list;
  
      // Проверяем, произошла ли прокрутка внутри слайдера
      if (sliderNode && sliderNode.contains(e.target)) {
        e.deltaY > 0 ? slider.slickNext() : slider.slickPrev();
      }
    }
  };
  
  useEffect(() => {
    document.addEventListener("wheel", handleMouseWheel);
  
    return () => {
      document.removeEventListener("wheel", handleMouseWheel);
    };
  }, [slider]);
  

  const toggleZoom = () => {
    setZoomed(!zoomed);
  };

  const [images, setImages] = useState([]);
  const apiUrl = "https://admin.mobilebühne.at";
  // const sceneId = 2; // Замените на нужный id сцены

  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

 

  const settings = {
    dots: false,
    infinite: true,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: images.length > 5 ? 5 : images.length,
    slidesToScroll: 1,
    // swipe: false, // Отключить прокрутку

    // autoplay: true,
    // autoplaySpeed: 1800,
    // wheel: true, // Enable mouse scroll
  };

  if (window.innerWidth <= 1043) {
    settings.vertical = false;
  }

  useEffect(() => {
    axios
      .get(`${apiUrl}/items/stages/${sceneId}?fields=gallery.directus_files_id`)
      .then((response) => {
        const gallery = response.data.data.gallery;

        if (Array.isArray(gallery) && gallery.length > 0) {
          const imageIds = gallery.map(
            (galleryItem) => galleryItem.directus_files_id
          );

          const imagePromises = imageIds.map((directus_files_id) =>
            axios
              .get(`${apiUrl}/files/${directus_files_id}`)
              .then((response) => ({
                directus_files_id,
                imageInfo: response.data,
              }))
          );

          Promise.all(imagePromises)
            .then((imageResults) => {
              setImages(imageResults);
              setSelectedImage(imageResults[0].directus_files_id);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [sceneId]);

  const activate = (e, image, index) => {
    e.stopPropagation();
    setSelectedImage(image.directus_files_id);
    setCurrentIndex(index);
  };

  const nextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[nextIndex].directus_files_id);
    setCurrentIndex(nextIndex);
  };

  const prevImage = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[prevIndex].directus_files_id);
    setCurrentIndex(prevIndex);
  };

  return (
    <main className={`gallery ${zoomed ? "zoomed" : ""}`}>
      <div className="galleryCont">
        <div className="galleryDefault">
          <div className="carousel-arrows">
            <button className="arrow-left" onClick={prevImage}>
              &#60;
            </button>

            <button className="arrow-right" onClick={nextImage}>
              &#62;
            </button>
          </div>
          {selectedImage && (
            <div
              className={`hero ${zoomed ? "zoomed" : ""}`}
              onClick={toggleZoom}
            >
              <img
                className={`stageImg ${zoomed ? "zoomed" : ""}`}
                src={`${apiUrl}/assets/${selectedImage}`}
                alt="Selected Image"
              />
            </div>
          )}
        </div>

        {/* <div className="carousel-arrows1"></div> */}
        <div className="gallerySlider">
          <Slider ref={(slider) => setSlider(slider)} {...settings}>
            {images.map((image, index) => (
              <div
                key={index}
                className={`slick-slide ${zoomed ? "zoomed" : ""}`}
              >
                <img
                  className="galleryMini"
                  src={`${apiUrl}/assets/${image.directus_files_id}`}
                  alt={`Image ${image.directus_files_id}`}
                  // style={{
                  //   width: "170px",
                  //   height: "120px",
                  //   objectFit: "cover",
                  //   borderRadius: "10px",
                  // }}
                  onClick={(e) => activate(e, image, index)}
                />
                <p>{image.imageInfo.title}</p>
                {/* Other image details you want to display */}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </main>
  );
};

export default Gallery;
