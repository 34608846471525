// Register.js
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import "./register.css"; // Подключаем файл стилей

const API_BASE_URL = "https://admin.mobilebühne.at";

const Register = ({ onRegister }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [isSignUp, setIsSignUp] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const [error, setError] = useState(""); // Добавляем состояние для отображения ошибок

  const handleNameChange = (e, setName) => {
    const value = e.target.value;

    // Проверка, что введенные символы - только буквы
    if (/^[a-zA-Z]+$/.test(value) || value === "") {
      setName(value);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
  
    // Простая проверка, что введенные символы соответствуют формату email
    setEmail(value);
  };
  

  useEffect(() => {
    // Проверка наличия информации об аутентификации при загрузке компонента
    const authenticated = localStorage.getItem("authenticated") === "true";
    if (authenticated) {
      // Если пользователь уже залогинен, перенаправляем его на главную страницу
      navigate("/user");
    }
  }, []); // Пустой м

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!email || !password || !first_name || !last_name) {
      setError("All fields are required"); // Устанавливаем сообщение об ошибке
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/users`, {
        email,
        password,
        first_name,
        last_name,
        role: "83f337f6-5042-4da4-963e-987d621ff980",
      });

      console.log("User created:", response.data);

      // Display success message
      setShowSuccessMessage(true);
      setSuccessMessage("Registration successful!");

      // Hide success message after 3 seconds
      setTimeout(() => {
        setShowSuccessMessage(false);
        setSuccessMessage("");
      }, 3000);

      // Navigate to the home page after 3 seconds
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (error) {
      console.error("Registration failed:", error);
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Email and password are required"); // Устанавливаем сообщение об ошибке
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error(`Login failed: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("User authenticated:", data);

      const accessToken = data.data.access_token;
      const refreshToken = data.data.refresh_token;

      // Log the tokens to the console
      console.log("accessToken:", accessToken);
      console.log("refreshToken:", refreshToken);

      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);

      onRegister(data);

      // Display success message
      setShowSuccessMessage(true);
      setSuccessMessage("Login successful!");

      // Redirect to the user page
      navigate("/user");
    } catch (error) {
      console.error("Authentication failed:", error);

      // Display error message
      setShowSuccessMessage(true);
      setSuccessMessage(error.message);
    }
  };

  return (
    <div className={`container ${isSignUp ? "right-panel-active" : ""}`}>
      <div className="form-container sign-up-container">
        <form>
          <h3>Create Account</h3>
          <div className="regFields">
            <label className="regText">First Name:</label>{" "}
            <input
              className="regInput"
              type="string"
              value={first_name}
              onChange={(e) => handleNameChange(e, setFirst_name)}
            />
            <label className="regText">Last Name:</label>
            <input
              className="regInput"
              type="string"
              value={last_name}
              onChange={(e) => handleNameChange(e, setLast_name)}
            />
            <label className="regText">Email:</label>
            <input
              className="regInput"
              type="email"
              value={email}
              onChange={(e) => handleEmailChange(e)}
              />
            <label className="regText">Password:</label>
            <input
              className="regInput"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <div className="error-message">{error}</div>}{" "}
          {/* Отображаем сообщение об ошибке */}
          <button className="regBtn" onClick={handleRegister}>
            Register
          </button>
          {/* Display success message */}
          {showSuccessMessage && (
            <div className="success-message">{successMessage}</div>
          )}
        </form>
      </div>

      <div className="form-container sign-in-container">
        <form>
          <h1>Sign In</h1>
          <div className="regFields">
            {/* Поле ввода для email */}
            <label className="regText">Email:</label>
            <input
              className="regInput"
              type="text"
              placeholder="Enter your email"
              // Добавляем state для email
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            {/* Поле ввода для password */}
            <label className="regText">Password:</label>
            <input
              className="regInput"
              type="password"
              placeholder="Enter your password"
              // Добавляем state для password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          {/* Кнопка входа */}
          <button className="regBtn" onClick={handleSignIn}>
            Sign In
          </button>

          {/* Сообщение об успешном входе */}
          {showSuccessMessage && (
            <div className="success-message">{successMessage}</div>
          )}
        </form>
      </div>

      {/* Overlay */}
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-panel overlay-left">
            <h1 className="regHeadText">Welcome Back</h1>
            <p className="regParText">
              To keep connected with us please login with your personal info
            </p>
            <button className="ghost" onClick={() => setIsSignUp(false)}>
              Sign In
            </button>
          </div>
          <div className="overlay-panel overlay-right">
            <h1 className="regHeadText">Hello, Friend</h1>
            <p className="regParText">
              Enter your personal details and start the journey with us
            </p>
            <button className="ghost" onClick={() => setIsSignUp(true)}>
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
