import React from "react";
import Gallery from "./gallery";
import { useState } from "react";

import peopleImg from "../img/people.svg";
import measureImg from "../img/measureBG2.svg";
import weightImg from "../img/weight.svg";
import timeImg from "../img/time-lapse2.svg";
import GalleryComponent from "./gallery";
import GridAnimation from "./GridAnimation";


const parseSizeString = ({ size }) => {
  const [width, height] = size.split('x').map(Number);

  return {
    width,
    height,
  };
};


const Modal = ({ onClose, title, content }) => {
  const [sceneClicked, setSceneClicked] = useState(false);

  if (!content) {
    return null;
  }

  const {
    id,
    stageName,
    description,
    gallery,
    stage_location,
    preparation_time,
    size,
    weight,
    people_to_prepare,
  } = content;
  const handleSceneClick = () => {
    setSceneClicked(true);
  };

  // const images = [
  //   img && `https://admin.mobilebühne.at/assets/${img}`,
  //   img2 && `https://admin.mobilebühne.at/assets/${img2}`,
  //   img3 && `https://admin.mobilebühne.at/assets/${img3}`,
  //   img4 && `https://admin.mobilebühne.at/assets/${img4}`,
  //   img5 && `https://admin.mobilebühne.at/assets/${img5}`,
  //   img6 && `https://admin.mobilebühne.at/assets/${img6}`,
  //   img7 && `https://admin.mobilebühne.at/assets/${img7}`,
  //   img8 && `https://admin.mobilebühne.at/assets/${img8}`,
  //   img9 && `https://admin.mobilebühne.at/assets/${img9}`,
  //   img10 && `https://admin.mobilebühne.at/assets/${img10}`,
  // ].filter(Boolean);
  const { width, height } = parseSizeString({ size });

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="modalCont">
          <div className="closeContainer">
            <div className="closeBtn">
              <button className="close-button" onClick={onClose}>
                &times;
              </button>
            </div>
          </div>

          {/* <p>ID{id}</p> */}
          <div className="modalHeader">
            <h1 className="modalStageName">{stageName}</h1>

            <div className="infoContainer">
              <div className="infoImgContainer">
                <img className="modalImg" src={measureImg} alt="People Image" />
                <p>{size}</p>
              </div>
              <div className="infoImgContainer">
                <img className="modalImg" src={weightImg} alt="People Image" />
                <p>{weight} kg</p>
              </div>
              <div className="infoImgContainer">
                <img className="modalImg" src={timeImg} alt="People Image" />
                <p>{preparation_time} st</p>
              </div>
              <div className="infoImgContainer">
                <img className="modalImg" src={peopleImg} alt="People Image" />
                <p>{people_to_prepare}</p>
              </div>
              {/* <GridAnimation width = {width} height = {height} /> */}

            </div>
          </div>

          <div className="modalContainer">
            <div className="imgContainer">
              {/* <Gallery images={images} /> */}
              <GalleryComponent gallery={gallery} sceneId={id} />
              {/* <img
                className="stageImg"
                src={`https://admin.mobilebühne.at/assets/${img}`}
                alt="Stage Image"
              /> */}
            </div>
            <div className="descContainer">
              <p className="modalDesc">Description: {description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
